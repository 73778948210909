import { 
    Box,
    TextField,
    Button,
    Alert
   } from '@mui/material';
import React, { useState } from "react";
import { Link } from 'react-router-dom'

import "./Login.css"

const API = process.env.REACT_APP_API_URL

const Login = () => {

    const [username, setUsername] = useState("none");
    const [password, setPassword] = useState("none");
    const [show_alert, setShowAlert] = useState(false)

    const validate = () => {
        var body = {
            username: username,
            password: password,
        }
    
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };
    
        fetch(API + "/user/login/", requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.status === "ok") {
                document.cookie = "token=" + result.token;
                window.location.href = "/tests"
            } else {
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                }, 2000)
            }
        })
        .catch(error => console.log('error', error));
    }

    return (
        <Box className="login_page">
            <Box className="login_header">
                    <img src="/icon.png" className='login_icon' alt="icono"/>
                    <span className='login_title'>Atlas</span>
            </Box>
            <Box className="login_container">
                <Box className="login_text_container">
                    Usuarie
                </Box>
                <Box className="login_textfield_container">
                    <TextField
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        onChange={(event) => {
                            setUsername(event.target.value)
                        }}        
                    />
                </Box>
                <Box className="login_text_container">
                    Contraseña
                </Box>
                <Box className="login_textfield_container">
                    <TextField
                        fullWidth
                        type="password"
                        variant="outlined"
                        color="secondary"
                        onChange={(event) => {
                            setPassword(event.target.value)
                        }}      
                    />
                </Box>

                <Box className="login_button_container">
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            validate()
                        }}
                    >
                        Iniciar sesión
                    </Button>
                </Box>
            </Box>
            <Box className="login_button_container">
                        <Link to="/password" className="login_link"> Recuperar contraseña </Link>
                    </Box>
            { show_alert ? 
                <Box className="alert_container">
                    <Alert severity="error">
                        Algo ha ido mal
                    </Alert>
                </Box>
            : <></> }
        </Box>
    )
}

export default Login