import { 
    Box,
    TextField,
    Button,
    Alert
   } from '@mui/material';
import React, { 
    useState
} from "react";
import "./Login.css"  

const API = process.env.REACT_APP_API_URL

const Password = () => {

    const [token, setToken] = useState("none");
    const [password_1, setPassword1] = useState("none");
    const [password_2, setPassword2] = useState("none");
    const [show_alert, setShowAlert] = useState(false)
    const [alert_code, setAlertCode] = useState("")
    const [alert_message, setAlertMessage] = useState("")

    const validate = () => {

        if (password_1 !== password_2) {
            setAlertMessage("Las contraseñas no coinciden")
            setAlertCode("fail")
            setShowAlert(true)
            setTimeout(() => {
                setShowAlert(false)
            }, 2000)
        } else {
            var body = {
                password: password_1,
                token: token,
            }
        
            var headers = new Headers();
            headers.append("Content-Type", "application/json");
        
            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body),
            };
        
            fetch(API + "/user/password/", requestOptions)
            .then(response => response.json())
            .then(result => {
                setAlertMessage(result.status === "ok" ? "Cambiado" : "Algo ha ido mal")
                setAlertCode(result.status)
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                    if (result.status === "ok")
                        window.location.href = "/login"
                }, 2000)
            })
            .catch(error => console.log('error', error));
        }
    }

    return (
        <Box className="login_page">
            <Box className="login_header">
                    <img src="/icon.png" className='login_icon' alt="icono"/>
                    <span className='login_title'>Atlas</span>
            </Box>
            <Box className="login_container">
                <Box className="login_text_container">
                    Token
                </Box>
                <Box className="login_textfield_container">
                    <TextField
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        onChange={(event) => {
                            setToken(event.target.value)
                        }}        
                    />
                </Box>
                <Box className="login_text_container">
                    Contraseña
                </Box>
                <Box className="login_textfield_container">
                    <TextField
                        fullWidth
                        type="password"
                        variant="outlined"
                        color="secondary"
                        onChange={(event) => {
                            setPassword1(event.target.value)
                        }}      
                    />
                </Box>
                <Box className="login_text_container">
                    Contraseña, otra vez
                </Box>
                <Box className="login_textfield_container">
                    <TextField
                        fullWidth
                        type="password"
                        variant="outlined"
                        color="secondary"
                        onChange={(event) => {
                            setPassword2(event.target.value)
                        }}      
                    />
                </Box>

                <Box className="login_button_container">
                    <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        onClick={() => {
                            validate()
                        }}
                    >
                        Cambiar contraseña
                    </Button>
                </Box>
            </Box>
            { show_alert ? 
                <Box className="alert_container">
                    <Alert severity={alert_code === "ok" ? "success" : "error"}>
                        {alert_message}
                    </Alert>
                </Box>
            : <></> }
        </Box>
    )
}

export default Password